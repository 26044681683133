/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import OtherBranches from 'components/shared/OtherBranches'
import ButtonDefault from 'components/elements/ButtonDefault'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const BlogContent = styled.div`
  h3 {
    font-size: ${props => props.theme.font.size.xm};
    font-weight: ${props => props.theme.font.weight.xl};
    padding-top: 40px;
  }
`

const Story = styled(ParseContent)`
  p {
    font-size: ${props => props.theme.font.size.sm};
    color: ${props => props.theme.color.text.dark};
    line-height: 30px;
  }

  li {
    font-size: ${props => props.theme.font.size.sm};
    color: ${props => props.theme.color.text.dark};
    line-height: 30px;
  }

  & a {
    color: ${props => props.theme.color.text.contrast};
    font-weight: ${props => props.theme.font.weight.xl};
    text-decoration: underline;
  }
`

const ContactLink = styled.div``

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...BranchesFrag
    }
  }
`

const PageTemplate = ({
  data: {
    page: { title, wordpress_id, path, acf, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
      />

      <Hero left article small title={title} backUrl="/branches/" content={acf.banner} className="mb-lg-3" />

      <div className="container pb-5">
        <div className="row">
          <BlogContent className="col-lg-7 pr-lg-5">
            <Story content={acf.content.story} className="py-5" />
            <ContactLink className="pt-4 mb-5 d-flex flex-wrap justify-content-center align-items-center">
              <p className="mb-md-0 mb-3 mr-4 font-size-xm font-weight-xl">Meer weten over onze branches?</p>
              <ButtonDefault className="pb-5" icon="arrow" to="/contact">
                <p>Neem contact op</p>
              </ButtonDefault>
            </ContactLink>
          </BlogContent>
          <div className="col-lg-5 pb-lg-0 pb-4 pt-lg-5">
            <OtherBranches wpId={wordpress_id} />
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default PageTemplate
